import { DateTime, Interval } from 'luxon';
import { RRule, type RRuleSet, type Weekday } from 'rrule';

import {
  type BaseEventConfig,
  type BaseEventParticipant,
  type EventAvailabilityItem,
  type EventConfigDefinition,
  EventConfigType,
  type EventInterviewerDefinition,
  type EventOpportunity,
  type InterviewSlot,
  isLoopConfig,
  type LoopEventListItem,
  type LoopEventTemplate,
  type PhoneScreenListItem,
  type SignUpRestriction,
} from './models';
import { getHireIdDisplay } from '@/common/common-components';
import { ElevateDateTime, getDateTimeFromReference, isValidDate } from '@/common/date-time-helpers';
import { getReadableFromEnum } from '@/common/labels';
import { DAYS_OF_WEEK, type Nullable, type OptionalBool, type OptionalNumber, type OptionalString } from '@/common/models';
import { mapToObject } from '@/common/utility';
import type { CandidateDefinition } from '@/components/Candidate/models';
import {
  AvailabilityState,
  EventParticipantMode,
  EventType,
  InterviewerRole,
  LoopConfigBrMode,
  OrgConfigDefinitionType,
  type Profile,
  ProfileAvailability,
  RecursionInterval,
  RoutingState,
} from '@/graphql/types';
import type {
  LoopRoutingAttempt,
  RawEventConfig,
  RawInterviewer,
  RawLoopEvent,
  RawPhoneScreen,
  WithRequiredNotNull,
} from '@/models';
import { DEFAULT_MAX_PARTICIPANT_SHADOWS, LEAD_ROLES, LOOP_DEFAULT_DURATION, SHADOW_ROLES } from '@/utilities/constants';
import { encodeToPublicID } from '@/utilities/uuid-encoder';

export const BR_KEY = 'BAR_RAISER';

export const BR_PARTICIPANT = {
  id: BR_KEY,
  name: 'Bar raiser',
  calibrationId: BR_KEY,
  description: '',
} as const satisfies BaseEventParticipant;

const rruleWeekDays = [RRule.SU, RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA];

function daysUntilEvent(eventDateTime: ElevateDateTime): number | undefined {
  if (!eventDateTime) return;
  const luxon = eventDateTime.toLuxon()?.toUTC();
  const now = DateTime.now().toUTC();
  if (!luxon) return;
  let intervalNegated = false;
  let interval: Interval;
  if (luxon.toMillis() > now.toMillis()) {
    interval = now.until(luxon);
  } else {
    interval = luxon.until(now) as Interval;
    intervalNegated = true;
  }
  if (!interval.isValid) return;
  let diff = interval.length('days');
  if (!Number.isFinite(diff)) return;
  if (intervalNegated) {
    diff = -diff;
  }

  return Math.ceil(diff);
}

export function processEventInterviewers(interviewers: Nullable<RawInterviewer[]>): EventInterviewerDefinition[] {
  return (interviewers ?? []) // If we have no profile (shouldn't happen) the interviewer entry isn't relevant
    .filter((interviewer): interviewer is WithRequiredNotNull<RawInterviewer, 'profile'> => !!interviewer.profile)
    .map((interviewer) => ({
      alias: interviewer.profile.alias,
      id: interviewer.id,
      isBarRaiser: interviewer.isBarRaiserInterviewer ?? false,
      isDirty: false,
      name: interviewer.profile.name,
      participantConfigId: interviewer.participantConfigID,
      profileId: interviewer.profile.id,
      role: InterviewerRole[interviewer.interviewerRole],
      timezoneId: interviewer.profile.preferredTimezone ? JSON.parse(interviewer.profile.preferredTimezone)?.id : null,
      startDateTime: interviewer.startDateTime,
      endDateTime: interviewer.endDateTime,
      sourceOrgId: interviewer.sourceOrgID,
    }));
}

export function processEventConfig(
  eventConfig: Nullable<RawEventConfig>,
  configType: EventConfigType,
  timezoneId: OptionalString
): EventConfigDefinition | null {
  if (!eventConfig) return null;
  const definition: BaseEventConfig = {
    breakDurationMinutes: eventConfig?.breakDuration,
    candidateLevels: eventConfig.candidateLevels ?? [],
    createdAt: ElevateDateTime.fromUTC(eventConfig.createdAt, timezoneId).displayDateTime,
    description: eventConfig.description,
    durationMinutes: eventConfig.duration,
    id: eventConfig.id,
    filterId: encodeToPublicID(eventConfig.id),
    isActive: eventConfig.isActive ?? false,
    name: eventConfig.name ?? '',
    orgId: eventConfig.orgID,
    participants: eventConfig?.participantConfigs?.items
      ?.map((config) => ({
        calibrationId: config.calibrationConfigID,
        description: config.description ?? '',
        id: config.id,
        name: config.name ?? '',
      }))
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
    filterParticipants: eventConfig.participantConfigs?.items?.map((config) => config.name!).sort(),
    statusValue: eventConfig.isActive ? 'Active' : 'Inactive',
    updatedAt: ElevateDateTime.fromUTC(eventConfig.updatedAt, timezoneId).displayDateTime,
  };
  if (configType === OrgConfigDefinitionType.PHONESCREEN_CONFIG) {
    return { ...definition, configType };
  }
  return {
    ...definition,
    configType,
    barRaiserMode: eventConfig.brMode ? LoopConfigBrMode[eventConfig.brMode] : LoopConfigBrMode.NOT_REQUIRED,
    isPushRouting: eventConfig.isPushRouting ?? false,
    maxShadows: eventConfig.maxShadowParticipants,
  };
}

function processBaseLoopEvent(item: RawLoopEvent, orgName: OptionalString, preferredTimezoneId?: string) {
  return {
    configName: item.eventConfig?.name,
    createdAt: ElevateDateTime.fromUTC(item.createdAt, preferredTimezoneId).displayDateTime,
    date: item.date,
    debriefBooked: item.debriefbooked,
    eventConfig: processEventConfig(item.eventConfig, OrgConfigDefinitionType.LOOP_CONFIG, preferredTimezoneId),
    designatedRCAlias: item.designatedRC?.alias,
    designatedRCName: item.designatedRC?.name,
    designatedRCID: item.designatedRC?.id,
    eventLeadAlias: item.eventlead?.alias,
    eventLeadName: item.eventlead?.name,
    eventLeadId: item.eventlead?.id,
    id: item.id,
    filterId: encodeToPublicID(item.id),
    isVirtual: item.virtualevent,
    location: item.location ?? '',
    maxCandidates: item.maxcandidates ?? 0,
    orgId: item.orgID,
    filterOrgId: encodeToPublicID(item.orgID),
    orgName: orgName ?? '',
    recruitingPOCAlias: item.recruitingPOC?.alias,
    recruitingPOCName: item.recruitingPOC?.name,
    recruitingPOCId: item.recruitingPOC?.id,
    restrictLeadSignup: item.restrictedLeadSignup,
    restrictShadowSignup: item.restrictedShadowSignup,
    startTime: item.eventstarttime,
    status: item.status,
    tags: item.taglist?.filter((tag): tag is string => !!tag),
    updatedAt: ElevateDateTime.fromUTC(item.updatedAt, preferredTimezoneId).displayDateTime,
  };
}

export function processLoopTemplate(
  item: RawLoopEvent,
  orgName: OptionalString,
  preferredTimezoneId?: string
): LoopEventTemplate {
  return processBaseLoopEvent(item, orgName, preferredTimezoneId);
}

export function convertSignupRestriction(lead?: OptionalBool, shadow?: OptionalBool): SignUpRestriction | undefined {
  if (lead && shadow) return 'Lead & Shadow';
  if (lead && !shadow) return 'Lead';
  if (!lead && shadow) return 'Shadow';
  return undefined;
}

export function processLoopEvent(
  item: RawLoopEvent,
  orgName: OptionalString,
  preferredTimezoneId?: string
): LoopEventListItem {
  const eventConfig = item.eventConfig;
  const participants = eventConfig?.participantConfigs?.items ?? [];
  const requiredParticipants = participants.length + (eventConfig?.brMode === LoopConfigBrMode.FULL ? 1 : 0);
  const activeParticipants = item.interviewers?.items?.filter((opt) => LEAD_ROLES.has(opt.interviewerRole)).length ?? 0;
  const eventDateTime = ElevateDateTime.fromUTC({ date: item.date, time: item.eventstarttime }, preferredTimezoneId);
  return {
    ...processBaseLoopEvent(item, orgName, preferredTimezoneId),
    eventType: EventType.LOOP,
    candidateCount: item.candidates?.items?.length ?? 0,
    candidates: item.candidates?.items,
    commentCount: item.comments?.items?.length ?? 0,
    daysTillEvent: daysUntilEvent(eventDateTime),
    debriefBooked: item.debriefbooked,
    eventReady: activeParticipants === requiredParticipants,
    signUpRestriction: convertSignupRestriction(item.restrictedLeadSignup, item.restrictedShadowSignup),
    configName: item.eventConfig?.name,
    interviewers: processEventInterviewers(item.interviewers?.items),
    locked: item.locked ?? false,
    lockedbyAlias: item.lockedby?.alias,
    lockedbyID: item.lockedby?.id,
    eventDateTime: eventDateTime.displayDateTime,
  };
}

export function processPhoneScreen(
  item: RawPhoneScreen,
  orgName: string | undefined,
  preferredTimezoneId?: string
): PhoneScreenListItem {
  const requiredParticipants = item.eventConfig?.participantConfigs?.items?.length ?? 0;
  const activeParticipants = item.interviewers?.items?.filter((opt) => LEAD_ROLES.has(opt.interviewerRole)).length ?? 0;
  const eventLead = item.interviewers?.items?.find((opt) => LEAD_ROLES.has(opt.interviewerRole))?.profile;
  const eventShadow = item.interviewers?.items?.find((opt) => SHADOW_ROLES.has(opt.interviewerRole))?.profile;
  const eventDateTime = ElevateDateTime.fromUTC({ date: item.date, time: item.eventstarttime }, preferredTimezoneId);

  return {
    eventType: EventType.PHONE_SCREEN,
    availabilityId: 'availabilityID' in item ? item.availabilityID : null,
    candidateCount: item.candidates?.items?.length ?? 0,
    candidateHireUrl: item.candidates?.items?.[0]?.hireurl,
    candidateHireId: getHireIdDisplay(item.candidates?.items?.[0]?.hireurl),
    candidateName: item.candidates?.items?.[0]?.name,
    candidates: item.candidates?.items,
    commentCount: item.comments?.items?.length ?? 0,
    configName: item.eventConfig?.name,
    createdAt: ElevateDateTime.fromUTC(item.createdAt, preferredTimezoneId).displayDateTime,
    date: item.date,
    daysTillEvent: daysUntilEvent(eventDateTime),
    designatedRCAlias: item.designatedRC?.alias,
    designatedRCName: item.designatedRC?.name,
    eventConfig: processEventConfig(item.eventConfig, OrgConfigDefinitionType.PHONESCREEN_CONFIG, preferredTimezoneId),
    eventLeadAlias: eventLead?.alias,
    eventLeadName: eventLead?.name,
    eventShadowAlias: eventShadow?.alias,
    eventShadowName: eventShadow?.name,
    eventReady: activeParticipants === requiredParticipants,
    id: item.id,
    filterId: encodeToPublicID(item.id),
    interviewers: processEventInterviewers(item.interviewers?.items),
    routingHistory: [],
    locked: item.locked ?? false,
    orgId: item.orgID,
    filterOrgId: encodeToPublicID(item.orgID),
    orgName: orgName ?? '',
    startTime: item.eventstarttime,
    status: item.status,
    updatedAt: ElevateDateTime.fromUTC(item.updatedAt, preferredTimezoneId).displayDateTime,
    designatedRCID: item.designatedRC?.id,
    eventDateTime: eventDateTime.displayDateTime,
    tags: item.taglist ?? [],
  };
}

type PartialProfile = { timezoneId?: string } & Pick<
  Profile,
  'id' | 'alias' | 'level' | 'name' | 'preferredTimezone' | 'title'
>;

type PartialProfileAvailability = Omit<ProfileAvailability, 'profileID' | 'profile'>;

export function processEventAvailability(
  item: PartialProfileAvailability,
  profile: PartialProfile,
  orgName?: string,
  dateOverride?: string | null // mainly to support recurring availabilities where the date is fluid
): EventAvailabilityItem {
  // const timezoneId = profile.preferredTimezone ? JSON.parse(profile.preferredTimezone)?.id : profile.timezoneId;
  // This is fugly. Yes. It works though. Issue seen with useQuery cache and preferredTimezone coming in as a string.
  let timezoneId: string | undefined = undefined;
  if (profile.preferredTimezone) {
    try {
      const t = JSON.parse(profile.preferredTimezone);
      timezoneId = t.id ?? t;
    } catch {
      timezoneId = profile.preferredTimezone;
    }
  } else {
    timezoneId = profile.timezoneId;
  }
  /*
    The open/close times are stored in UTC based on the `availabilityDate`.
    For recurring availabilities that start and end on different sides of DST, we need this
    date as a reference point to roll the times forward and compensate for the DST boundary.

    Example:
      You live in NYC and save a recurring availability from 9-11 AM that starts on 2/25/2023 and ends on 5/17/2023.
      On 2/25/2023, NYC is on EST time (UTC-5). On 5/17/2023, NYC is on EDT time (UTC-4).

      If you wish to attend a 9-11 AM event on 2/26/2023, the event is at 14:00-16:00 UTC.
      If you wish to attend a 9-11 AM event on 5/16/2023, the event is at 13:00-15:00 UTC.
  */
  // This is the date the event is meant to be. For a single availability slot, this is always the `availabilityDate`
  // For a recurring slot, this will vary.
  if (!isValidDate(item.availabilityDate)) throw new Error('Availability date is not a valid date string');
  const referenceDate = item.availabilityDate;
  const targetDate = dateOverride || item.availabilityDate;
  const startDateUTC = dateOverride || item.availabilityDate;
  const startDateTimeOpenUTC = item.availabilityOpen;
  const startDateTimeCloseUTC = item.availabilityClose;
  const endDateUTC = item.recurringEndDate?.split('T')?.[0];
  let endDateTime: Nullable<ElevateDateTime>;
  if (endDateUTC && startDateTimeOpenUTC) {
    endDateTime = getDateTimeFromReference(startDateUTC, startDateTimeOpenUTC, endDateUTC, timezoneId);
  }
  if (!startDateTimeOpenUTC) throw new Error('Availability does not specify start time');
  if (!startDateTimeCloseUTC) throw new Error('Availability does not specify end time');
  if (!timezoneId) throw new Error('Missing Timezone Id');
  const startDateTimeOpen = getDateTimeFromReference(referenceDate, startDateTimeOpenUTC, targetDate, timezoneId);
  const startDateTimeClose = getDateTimeFromReference(referenceDate, startDateTimeCloseUTC, targetDate, timezoneId);
  // Now returns an EventAvailabilityItem[] array.
  return {
    daysOfWeek: item.daysOfWeek,
    candidateHireId: getHireIdDisplay(item.hireurl),
    candidateHireUrl: item.hireurl,
    id: item.id,
    filterId: encodeToPublicID(item.id),
    seriesEndDateTime: endDateTime,
    seriesEndDate: endDateTime?.date,
    eventType: item.eventType as EventType,
    expired: item.expired ?? false,
    orgId: item.orgID,
    filterOrgId: encodeToPublicID(item.orgID),
    orgName: orgName,
    preferredTimezoneId: timezoneId,
    profileAlias: profile.alias,
    profileId: profile.id,
    profileLevel: profile.level ?? 4,
    profileLevelFilter: `L${profile.level}`,
    profileName: profile.name ?? '',
    recurrence: item.recursion ? RecursionInterval[item.recursion] : null,
    requester: item.requester,
    rrule: item.rrule,
    sendCalendarInvite: item.sendCalendarInvite,
    slotOpen: startDateTimeOpen,
    slotClose: startDateTimeClose,
    slotDate:
      item.recursion || item.availabilityDate <= (startDateTimeOpen.dateUTC ?? '') // for single slots in the past
        ? startDateTimeOpen.date
        : item.availabilityDate,
    slotStartTime: startDateTimeOpen?.displayTime,
    slotEndTime: startDateTimeClose?.displayTime,
    createdAt: ElevateDateTime.fromUTC(item.createdAt, timezoneId).displayDate,
    state: item.state ? AvailabilityState[item.state] : null,
    title: profile.title,
    availabilitySeriesID: item.availabilitySeriesID,
    isPartOfSeries: !!item.availabilitySeriesID,
    updatedAt: ElevateDateTime.fromUTC(item.createdAt, timezoneId).displayDateTime,
  };
}

function filterKey(participantId: OptionalString): string {
  return participantId
    ? participantId === BR_KEY
      ? participantId
      : participantId.slice(Math.max(0, participantId.length - 8))
    : '';
}

export function makeEventOpportunities<T extends LoopEventListItem[] | PhoneScreenListItem[]>(
  events: T,
  interviewerLevel: number,
  interviewerAlias: string,
  mode: EventParticipantMode,
  isOrgParticipationAllowed: (participantId: string, eventOrgId: string) => boolean,
  isInterviewerEligible: (participant: BaseEventParticipant, eventOrgId: string) => boolean
) {
  const eligibleEvents = events.filter(
    (event) =>
      !!event.eventConfig &&
      (mode === EventParticipantMode.SHADOW || // Shadows are currently not bound to strict candidate level requirements
        !event.eventConfig.candidateLevels?.length || // if there's no candidate restriction, event is eligible
        event.eventConfig.candidateLevels.some((level) => level <= interviewerLevel)) // If the interviewer is at or above any of the allowed levels
  );
  const ptxIdToName = new Map<string, string>();
  const calibratedParticipants = new Set<string>();
  const ptxIdToIsEligible = new Map<string, boolean>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const opportunities: (EventOpportunity<T[number]> & Record<string, any>)[] = eligibleEvents
    .map((event) => {
      const isRestricted = (mode === 'LEAD' ? event.restrictLeadSignup : event.restrictShadowSignup) ?? false;
      const available = isRestricted ? 'Restricted' : 'Available';
      const ineligible = 'Ineligible';
      let canClaim = true;
      let ineligibleReason: OptionalString;
      if (event.eventType === EventType.LOOP && event.locked) {
        canClaim = false;
        ineligibleReason = 'Event is locked';
      }
      let canRelease = false;
      let canParticipate = true;
      const slotIdDisplay = new Map<string, string>();
      let maxShadows: OptionalNumber = null;
      // Filter participants if this is another org and the participant/mode doesn't allow sharing.
      const participants = (event.eventConfig?.participants ?? []).filter((opt) =>
        isOrgParticipationAllowed(opt.id, event.orgId)
      );
      if (isLoopConfig(event.eventConfig)) {
        maxShadows = event.eventConfig.maxShadows ?? DEFAULT_MAX_PARTICIPANT_SHADOWS;
        // BR is a special kind of participant, so when the event calls for one, we add the entry manually.
        if (event.eventConfig.barRaiserMode === LoopConfigBrMode.FULL) {
          participants.push({ ...BR_PARTICIPANT, name: mode === 'SHADOW' ? 'BRIT' : BR_PARTICIPANT.name });
        }
      }

      canParticipate = !!participants.length;
      for (const participant of participants) {
        const isEligible = isInterviewerEligible(participant, event.orgId);
        // Add the ID to the running list of participants the user is calibrated for
        if (isEligible) calibratedParticipants.add(participant.id);

        ptxIdToName.set(participant.id, participant.name);
        ptxIdToIsEligible.set(participant.id, isEligible);
        slotIdDisplay.set(filterKey(participant.id), ptxIdToIsEligible.get(participant.id) ? available : ineligible);
      }

      let leadCount = 0;
      let maxParticipants = 0;
      let shadowCount = 0;
      const concreteParticipantIds = new Set<string>();
      const relevantParticipantIds = new Set(participants.map((opt) => opt.id));
      for (const interviewer of event.interviewers ?? []) {
        if (interviewer.alias === interviewerAlias) {
          canClaim = false;
          ineligibleReason = `Already a ${getReadableFromEnum(interviewer.role)} interviewer for this event`;
        }

        const participantId = interviewer.participantConfigId;
        if (participantId && !relevantParticipantIds.has(participantId)) continue;
        const interviewerRole = interviewer.role;
        let slotDisplayStatus: OptionalString;
        // If we're in lead mode and the slot has a lead/independent already, grab the alias and bump the count.
        if (mode === EventParticipantMode.LEAD && LEAD_ROLES.has(interviewerRole)) {
          leadCount++;
          slotDisplayStatus = interviewer.alias;
        }

        // If the event is led by an independent and we're in shadow mode, this slot is automatically ineligible
        if (mode === EventParticipantMode.SHADOW && interviewerRole === InterviewerRole.INDEPENDENT) {
          slotDisplayStatus = 'Ineligible';
          canClaim = false;
          ineligibleReason = 'This is an independent event, and not eligible for shadows';
        }
        if (mode === EventParticipantMode.SHADOW && SHADOW_ROLES.has(interviewerRole)) {
          slotDisplayStatus = interviewer.alias;
          shadowCount++;
        }

        canRelease = !!slotDisplayStatus && slotDisplayStatus === interviewerAlias ? true : canRelease;
        if (slotDisplayStatus && (participantId || interviewer.isBarRaiser)) {
          concreteParticipantIds.add(interviewer.isBarRaiser ? BR_KEY : participantId!);
          const idKey = filterKey(interviewer.isBarRaiser ? BR_KEY : participantId);
          if (idKey) slotIdDisplay.set(idKey, slotDisplayStatus);
        }
      }
      // Check
      const routingAttempts = (event?.routingHistory ?? []).filter(
        (attempt) => attempt.state === RoutingState.PENDING && relevantParticipantIds.has(attempt.participantConfigID ?? '')
      );
      for (const attempt of routingAttempts) {
        let slotDisplayStatus: OptionalString;
        if (concreteParticipantIds.has(attempt.isBarRaiser ? BR_KEY : attempt.participantConfigID!)) continue;
        canClaim = attempt.profile.alias === interviewerAlias ? true : canClaim;

        // If we're in lead mode and the slot has a lead/independent already, grab the alias and bump the count.
        if (mode === EventParticipantMode.LEAD && LEAD_ROLES.has(attempt.role)) {
          concreteParticipantIds.add(attempt.participantConfigID!);
          slotDisplayStatus = attempt.profile.alias;
          leadCount++;
        }
        if (mode === EventParticipantMode.SHADOW && SHADOW_ROLES.has(attempt.role)) {
          concreteParticipantIds.add(attempt.participantConfigID!);
          slotDisplayStatus = attempt.profile.alias;
          shadowCount++;
        }
        if (slotDisplayStatus && (attempt.participantConfigID || attempt.isBarRaiser)) {
          const idKey = filterKey(attempt.isBarRaiser ? BR_KEY : attempt.participantConfigID);
          if (idKey) slotIdDisplay.set(idKey, slotDisplayStatus);
        }
      }
      // Don't allow a claim if already full of interviewers
      maxParticipants += participants.length;
      switch (mode) {
        case EventParticipantMode.LEAD:
          canClaim = event.restrictLeadSignup ? false : canClaim;
          ineligibleReason = event.restrictLeadSignup ? 'Self signup is disabled for this event' : ineligibleReason;
          canParticipate = leadCount >= maxParticipants ? false : canParticipate;
          break;
        case EventParticipantMode.SHADOW:
          canClaim = event.restrictShadowSignup ? false : canClaim;
          ineligibleReason = event.restrictShadowSignup ? 'Shadow signup is disabled for this event' : ineligibleReason;
          canParticipate = shadowCount >= (maxShadows ?? maxParticipants) ? false : canParticipate;
          break;
        default:
          break;
      }

      return {
        ...event,
        ...mapToObject(slotIdDisplay),
        ineligibleReason,
        isRestricted,
        isEligibleToRelease: canRelease,
        isEligibleToClaim: canClaim,
        isEligibleToParticipate: canParticipate,
      };
    })
    .filter((op) => op.isEligibleToParticipate || op.isEligibleToRelease);
  return {
    opportunities,
    calibratedParticipants,
    participantToName: ptxIdToName,
    participantToEligible: ptxIdToIsEligible,
  };
}

export type UnixTimeRange = {
  start: number;
  end: number;
};
function sliceSubWindows(
  parentWindow: UnixTimeRange,
  segmentDurationSec: number,
  breakDurationSec: number,
  concurrentJobs: number
): UnixTimeRange[] {
  if (concurrentJobs <= 0 || parentWindow.end <= parentWindow.start || segmentDurationSec <= 0) {
    throw new Error(
      'Invalid input: Please provide a valid number of concurrent jobs, segment size, and a valid overall time range.'
    );
  }
  const windows: UnixTimeRange[] = [];
  const totalDurationSec = parentWindow.end - parentWindow.start;
  const windowSize = concurrentJobs * segmentDurationSec;
  const totalSegments = totalDurationSec / segmentDurationSec;
  const offset = totalSegments % concurrentJobs === 0 ? windowSize : segmentDurationSec;
  for (let start = parentWindow.start; start < parentWindow.end; start += offset) {
    const end = start + windowSize;
    if (end > parentWindow.end) break;
    windows.push({ start, end });
  }
  // If there's only a single window of time.
  // Then we just add the break duration to the window because there's no real way to decide the break ordering
  if (windows.length === 1 || breakDurationSec === 0) {
    windows[0].end += breakDurationSec;
    return windows;
  }

  // Otherwise if we have more than 1 possible window of time
  // Calculate the midpoint in the range provided
  const midPointEpochSec = parentWindow.start + totalDurationSec / 2;

  let breakApplied = false;
  for (const window of windows) {
    // if the window starts before the midpoint do nothing
    if (window.start < midPointEpochSec) continue;
    // But if it's after the midpoint we offset the window by the break duration
    window.start += breakDurationSec;
    window.end += breakDurationSec;
    breakApplied = true;
  }

  if (breakApplied) return windows;

  // Fall Through case
  for (const window of windows) {
    if (window.end < midPointEpochSec) continue;
    window.end += breakDurationSec;
  }
  return windows;
}

const toUnixTime = (date: ElevateDateTime) => Math.floor(date.epochMillis / 1000);

function getEventTimeRange(item: AvailableWindowParams): UnixTimeRange | undefined {
  const startEpochSec = item.eventDateTime.epochMillis / 1000;
  const durationSec = (item.duration ?? LOOP_DEFAULT_DURATION) * 60;
  const endEpochSec = startEpochSec + durationSec;
  return { start: startEpochSec, end: endEpochSec };
}

export function getEventTimeWwindows(item: AvailableWindowParams, podSize?: number): Nullable<UnixTimeRange[]> {
  if (item.participantCount === 0) return;
  let maxCandidates = podSize ?? item.maxCandidates;
  // Progressively increase the maxCandidates value until we reach a value that allows
  // a valid interview schedule (only divisors of participantCount are valid).
  while (item.participantCount % maxCandidates !== 0) {
    maxCandidates++;
  }

  const range = getEventTimeRange(item);
  if (!range) return;
  const breakDurationSec = item.breakDuration * 60;
  const segmentDurationSec = (range.end - range.start) / item.participantCount;
  return sliceSubWindows(range, segmentDurationSec, breakDurationSec, maxCandidates);
}

export type UnixRangeIndex = {
  [participantConfigId: string]: UnixTimeRange; // profileId
};

type UnixTimeRangeStringKey = `${number}-${number}`;

type AvailableWindowParams = {
  maxCandidates: number;
  participantCount: number;
  interviewers: EventInterviewerDefinition[];
  routingAttempts?: LoopRoutingAttempt[];
  eventDateTime: ElevateDateTime;
  duration: number;
  breakDuration: number;
};

function getTimeWindowKey(startTime: OptionalString, endTime: OptionalString): Nullable<UnixTimeRangeStringKey> {
  if (!(startTime && endTime)) return null;
  const start = toUnixTime(ElevateDateTime.fromUTC(startTime));
  const end = toUnixTime(ElevateDateTime.fromUTC(endTime));
  return `${start}-${end}`;
}

export function availableLoopTimeWindows(item: AvailableWindowParams) {
  const interviewers = item.interviewers;
  const routingAttempts = item.routingAttempts ?? [];
  const byParticipantID: UnixRangeIndex = {};
  const participantIDCount: { [participantID: string]: number } = {};
  const byTimeRange: { [timeRange: UnixTimeRangeStringKey]: UnixTimeRange } = {};
  const windows = getEventTimeWwindows(item);
  if (!windows) return;

  const baseParticipantsPerWindow = Math.floor(item.participantCount / windows.length);

  for (const window of windows) {
    const key = `${window.start}-${window.end}`;
    byTimeRange[key] = window;
  }

  for (const interviewer of interviewers) {
    const participantId = interviewer.participantConfigId ?? (interviewer.isBarRaiser ? BR_KEY : undefined);
    const key = getTimeWindowKey(interviewer.startDateTime, interviewer.endDateTime);
    if (!(participantId && key)) continue;
    if (byTimeRange[key] && !byParticipantID[participantId]) {
      if (typeof participantIDCount[key] !== 'number') participantIDCount[key] = 0;
      participantIDCount[key] += 1;

      byParticipantID[participantId] = byTimeRange[key];

      if (participantIDCount[key] >= baseParticipantsPerWindow) {
        delete byTimeRange[key];
      }
    }
  }

  // Remove any times that might be taken up by a routing attempt
  for (const attempt of routingAttempts) {
    // Skip for non-pending routing attempts
    if (attempt.state !== RoutingState.PENDING) continue;

    // Grab the attempt's participant ID
    const participantId = attempt.participantConfigID ?? (attempt.isBarRaiser ? BR_KEY : undefined);
    const key = getTimeWindowKey(attempt.startDateTime, attempt.endDateTime);
    if (!(participantId && key)) continue;

    if (byTimeRange[key] && !byParticipantID[participantId]) {
      if (typeof participantIDCount[key] !== 'number') participantIDCount[key] = 0;
      participantIDCount[key] += 1;

      byParticipantID[participantId] = byTimeRange[key];

      if (participantIDCount[key] >= baseParticipantsPerWindow) {
        delete byTimeRange[key];
      }
    }
  }
  return {
    assignedWindows: byParticipantID,
    openWindows: Object.values(byTimeRange),
  };
}

type CandidateSchedule = {
  [jobId: string]: { time: UnixTimeRange; participantId: string }[];
};

export function generateCandidatePodSchedule(loop: AvailableWindowParams, candidates: CandidateDefinition[]) {
  const allWindowws = availableLoopTimeWindows(loop);
  const individualWindows = getEventTimeWwindows(loop, 1);
  if (!(allWindowws && individualWindows)) return;
  const { assignedWindows } = allWindowws;
  const schedule: CandidateSchedule = {};
  const participantToWindowMap: { [participantId: string]: UnixTimeRange[] } = {};
  const participantUsedWindows: { [participantId: string]: Set<string> } = {};
  const usedParticipants: { [jobId: string]: Set<string> } = {};

  for (const [id, assignedWindow] of Object.entries(assignedWindows)) {
    participantToWindowMap[id] = individualWindows.filter((window) => doesWindowIntersect(window, assignedWindow));
    participantUsedWindows[id] = new Set();
  }

  for (let i = 0; i < loop.maxCandidates; i++) {
    const candidate = candidates.at(i);
    const candidateKey = candidate?.id ?? candidate?.name ?? `Candidate - ${i}`;

    schedule[candidateKey] = [];
    usedParticipants[candidateKey] = new Set();
    for (const window of individualWindows) {
      const availableParticipants = Object.keys(participantToWindowMap).filter(
        (id) =>
          participantToWindowMap[id].some((i) => i.start === window.start && i.end === window.end) &&
          !usedParticipants[candidateKey].has(id)
      );
      if (availableParticipants.length) {
        const assignedParticipant = availableParticipants[0];
        schedule[candidateKey].push({ time: { ...window }, participantId: assignedParticipant });
        usedParticipants[candidateKey].add(assignedParticipant);
        participantToWindowMap[assignedParticipant] = participantToWindowMap[assignedParticipant].filter(
          (pWindow) => pWindow.start !== window.start && pWindow.end !== window.end
        );
      }
    }
  }

  return {
    windows: individualWindows,
    schedule,
  };
}

function doesWindowIntersect(window1: UnixTimeRange, window2: UnixTimeRange): boolean {
  return (
    (window1.start >= window2.start && window1.end <= window2.end) ||
    (window2.start >= window1.start && window2.end <= window1.end)
  );
}

export function filterPendingRoutingAttempts(attempts: Nullable<LoopRoutingAttempt[]>, participantId: OptionalString) {
  return (attempts ?? [])
    .filter((i) => i.state === RoutingState.PENDING)
    .filter((i) => i.participantConfigID === participantId || (i.isBarRaiser && participantId === BR_PARTICIPANT.id));
}

/**
 * Sorts by start time, then end time, then locale comparison of strings
 */
export function sortInterviewSlots(a: InterviewSlot, b: InterviewSlot) {
  if (a.start === undefined && b.start === undefined) {
    return a.slotName.localeCompare(b.slotName);
  }
  if (a.start === undefined) return 1;
  if (b.start === undefined) return -1;
  if (a.start.epochMillis === b.start.epochMillis) {
    if (a.end === undefined && b.end === undefined) return a.slotName.localeCompare(b.slotName);
    if (a.end === undefined) return -1;
    if (b.end === undefined) return 1;
    if (a.end.epochMillis === b.end.epochMillis) return a.slotName.localeCompare(b.slotName);
    return a.end.epochMillis < b.end.epochMillis ? -1 : 1;
  }
  return a.start.epochMillis < b.start.epochMillis ? -1 : 1;
}

if (import.meta.vitest) {
  const { describe, it, expect } = import.meta.vitest;

  describe('sortInterviewSlots', () => {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const expectSorted = (unsorted: InterviewSlot[], knownSorted: InterviewSlot[]) =>
      expect([...unsorted].sort(sortInterviewSlots)).toEqual(knownSorted);
    const start = ElevateDateTime.fromUTC('2023-01-01');
    const end = ElevateDateTime.fromUTC('2023-01-02');
    it('Should sort slots with combinations of defined and undefined dates', () => {
      const slots: InterviewSlot[] = [
        { slotName: 'OnlyName' },
        { slotName: 'DefinedStart', start },
        { slotName: 'SameStartDifferentEnd', start, end },
      ];

      const correctSorting: InterviewSlot[] = [
        { slotName: 'DefinedStart', start },
        { slotName: 'SameStartDifferentEnd', start, end },
        { slotName: 'OnlyName' },
      ];
      expectSorted(slots, correctSorting);
    });

    it('Sort by name when all dates are equal and titles are different', () => {
      const slots: InterviewSlot[] = [
        { slotName: 'B', start, end },
        { slotName: 'A', start, end },
      ];
      const correctSorting: InterviewSlot[] = [
        { slotName: 'A', start, end },
        { slotName: 'B', start, end },
      ];
      expectSorted(slots, correctSorting);
    });
  });
}

function checkIsSlotRequested(slot1: EventAvailabilityItem, slot2: EventAvailabilityItem, dateTime: ElevateDateTime) {
  let splitId: string = '';
  if (slot1.availabilitySeriesID) {
    // availabilitySeriesID IDs will have a # in them followed by an integer which denotes which occurence in a series
    //   this particular slot is.
    splitId = slot1.availabilitySeriesID.split('#')[0];
  }
  return !!(splitId && splitId === slot2.id && slot1.slotOpen!.date === dateTime.date);
}

export function getSlotsFromConvertedRule(
  slot: EventAvailabilityItem,
  slotlist: EventAvailabilityItem[]
): EventAvailabilityItem[] {
  if (!slot.daysOfWeek) {
    return [];
  }
  const days = [
    slot.daysOfWeek & DAYS_OF_WEEK.SUN,
    slot.daysOfWeek & DAYS_OF_WEEK.MON,
    slot.daysOfWeek & DAYS_OF_WEEK.TUE,
    slot.daysOfWeek & DAYS_OF_WEEK.WED,
    slot.daysOfWeek & DAYS_OF_WEEK.THU,
    slot.daysOfWeek & DAYS_OF_WEEK.FRI,
    slot.daysOfWeek & DAYS_OF_WEEK.SAT,
  ];
  const rruleDays: Weekday[] = [];
  for (const [i, day] of days.entries()) {
    if (day) rruleDays.push(rruleWeekDays[i]);
  }
  // Here we use a split to handle the case where the time includes a timezone, i.e. PST.
  //   We specify the timezone anyway in the second arg.
  const endDateTime = ElevateDateTime.fromUTC(
    { date: slot.seriesEndDate, time: slot.slotStartTime.split(' ')[0] },
    slot.preferredTimezoneId
  );

  const rule = new RRule({
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday: rruleDays,
    dtstart: slot.slotOpen?.toLuxon()?.toJSDate(),
    until: endDateTime.toLuxon()?.toUTC().toJSDate(),
  });
  return getSlotsFromRule(rule, slot, slotlist);
}

export function getSlotsFromRule(
  rule: RRule | RRuleSet,
  slot: EventAvailabilityItem,
  slotlist: EventAvailabilityItem[]
): EventAvailabilityItem[] {
  let counter = 0;
  const occurences: EventAvailabilityItem[] = [];
  const duration = slot.slotOpen && slot.slotClose ? slot.slotClose.getMinutesSince(slot.slotOpen) : 0;

  rule.all().map((occurence) => {
    const max = rule.toString().includes('DAILY') ? 30 : 7;
    if (counter < max && Date.now() < occurence.getTime()) {
      const dateTime = ElevateDateTime.fromJSDate(occurence, slot.preferredTimezoneId);
      // Check to see if there is already a request against this slot..
      let foundRequest = false;
      for (const s of slotlist) {
        // if (checkIsSlotRequested(s, slot, dateTime)) {
        if (checkIsSlotRequested(s, slot, dateTime)) {
          foundRequest = true;
          break;
        }
      }
      if (!foundRequest) {
        occurences.push({
          ...slot,
          id: `${slot.id}#${counter}`,
          slotDate: dateTime.dateUTC,
          slotOpen: dateTime,
          slotClose: dateTime.addMinutes(duration),
          isPartOfSeries: true,
          availabilitySeriesID: slot.id,
        });
      }
      counter++;
    }
  });
  return occurences;
}
